<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card class="mx-auto pt-2" max-width="500px" elevation="8">
        <v-card-title class="mt-2 justify-center py-0">
          <v-label>
            <h3>{{ labels.title }}</h3>
          </v-label>
        </v-card-title>
        <v-card-text v-if="!emailSent">
          <v-form ref="forgotPasswordForm">
            <v-container class="mb-0 pb-0">
              <v-row no-gutters>
                <v-col>
                  <v-alert
                    v-if="error && typeof error === 'string'"
                    type="error"
                    >{{ error }}</v-alert
                  >
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-alert v-if="error.errors" type="error">{{
                    error.errors.email[0]
                  }}</v-alert>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    :label="labels.email"
                    name="email"
                    prepend-inner-icon="mdi-email-outline"
                    type="text"
                    v-model="email"
                    v-on:change="resetError"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-text v-if="emailSent">
          <v-container class="mb-0 pb-0">
            <v-row no-gutters>
              <v-col>
                <v-alert type="success">{{ labels.submitted }}</v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center mt-0 pt-0">
          <v-btn width="150px" large rounded to="/login">{{
            labels.return
          }}</v-btn>
          <v-btn
            color="primary"
            width="150px"
            large
            rounded
            @click="sendForgotPassword"
            :loading="loading"
            v-if="!emailSent"
            >{{ labels.submit }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "ForgotPassword",
  props: {},
  data: () => ({
    email: "",
    loading: false,
    emailSent: false,
  }),
  created() {},
  beforeMount() {
    this.resetError();
  },
  beforeDestroy() {
    this.resetError();
  },
  methods: {
    resetError() {
      this.$store.commit("auth/SET_ERROR", {});
    },
    sendForgotPassword() {
      this.resetError();
      if (this.$refs.forgotPasswordForm.validate()) {
        this.loading = true;
        this.$store
          .dispatch("auth/forgotPassword", this.email)
          .then(() => {
            this.loading = false;
            if (Object.keys(this.error).length === 0) {
              this.emailSent = true;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    error: {
      get() {
        return this.$store.state.auth.error;
      },
    },
    labels: {
      get() {
        return this.$store.getters["labels"]("forgot_password");
      },
    },
    rules: {
      get() {
        return this.$store.getters["rules"];
      },
    },
  },
};
</script>