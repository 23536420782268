<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card
        class="mx-auto pt-2"
        max-width="500px"
        elevation="8"
        v-if="!authenticated"
      >
        <v-card-title class="mt-2 justify-center py-0">
          <v-label>
            <h2>{{ labels.title }}</h2>
          </v-label>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="loginForm">
            <v-container class="mb-0 pb-0">
              <v-row no-gutters>
                <v-col>
                  <v-alert
                    v-if="error && typeof error === 'string'"
                    type="error"
                    >{{ error }}</v-alert
                  >
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-alert v-if="error.errors" type="error">{{
                    error.errors.email[0]
                  }}</v-alert>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    :label="labels.email + '*'"
                    name="email"
                    prepend-inner-icon="mdi-account"
                    type="text"
                    v-model="form.email"
                    v-on:change="resetError"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    id="password"
                    :label="labels.password + '*'"
                    name="password"
                    prepend-inner-icon="mdi-lock"
                    v-model="form.password"
                    v-on:change="resetError"
                    @keydown.enter="login"
                    :rules="[rules.required]"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters align="center" justify-sm="center">
                <v-col cols="6" sm="6">
                  <v-checkbox
                    v-model="form.remember"
                    :label="labels.remeber_me"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-btn
                    text
                    color="primary"
                    to="/forgot-password"
                    style="text-transform: none !important"
                    >{{ labels.forgot_password }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center mt-0 pt-0">
          <v-container fluid class="pt-0">
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="primary"
                  width="200px"
                  large
                  rounded
                  @click="login"
                  :loading="loading"
                  >{{ labels.submit }}</v-btn
                >
              </v-col>
            </v-row>
            <v-divider class="mt-1"></v-divider>
            <v-row no-gutters class="mt-2" align="center">
              <v-col cols="12" class="text-center">
                <v-label>{{ labels.no_account }} </v-label>
                <v-btn
                  text
                  color="primary"
                  to="/signup"
                  style="text-transform: none !important"
                  >{{ labels.register }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    redirect: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    form: {
      email: "",
      password: "",
      remember: false,
    },
    showPassword: false,
    loading: false,
    loadingUser: false,
  }),
  methods: {
    login() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        this.resetError();
        this.$store
          .dispatch("auth/login", this.form)
          .then(() => {
            this.$store.dispatch("auth/getCurrentUser").then(() => {
              this.user ? (this.$vuetify.lang.current = this.user.lang) : "";
              this.$store.dispatch("auth/redirectTo");
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    resetError() {
      this.$store.commit("auth/SET_ERROR", {});
    },
  },
  computed: {
    error: {
      get() {
        return this.$store.state.auth.error;
      },
    },
    authenticated: {
      get() {
        return this.$store.getters["auth/isAuthenticated"];
      },
    },
    userCan: {
      get() {
        return this.$store.getters["auth/currentUserMenuCan"];
      },
    },
    labels: {
      get() {
        return this.$store.getters["labels"]("login");
      },
    },
    rules: {
      get() {
        return this.$store.getters["rules"];
      },
    },
    user: {
      get() {
        return this.$store.state.auth.user;
      },
    },
  },
};
</script>