<template>
  <div>
    <app-form-page
      :breadcrumbs="items"
      :title="labels.title"
      :loading="progress"
      :remove-buttons="true"
      :entity="entity"
    >
      <template v-slot:custom-buttons="{ can, lb }">
        <v-btn
          class="mr-1"
          :loading="updating"
          @click="updateProfile"
          v-if="can.edit"
        >
          <v-icon left>mdi-content-save-outline</v-icon>
          {{ lb.update }}
        </v-btn>
        <v-btn
          :loading="updatingPassword"
          @click="showChangePasswordDialog = true"
          v-if="can.edit"
        >
          <v-icon left>mdi-form-textbox-password</v-icon>
          {{ lb.change_password }}
        </v-btn>
      </template>
      <template v-slot:content>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-row align="center" justify="center">
                <v-col cols="12" align="center" justify="center">
                  <v-avatar size="128" :color="avatar ? '' : 'blue'">
                    <v-img :src="avatar" v-if="avatar"></v-img>
                    <span v-if="!avatar" class="white--text display-3">{{
                      userInitials
                    }}</span>
                  </v-avatar>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" align="center" justify="center">
                  <v-btn tile @click="changeAvatarClick" :loading="uploading">
                    <v-icon left> mdi-image-edit-outline </v-icon>
                    {{ labels.edit_avatar }}
                  </v-btn>
                  <input
                    class="d-none"
                    type="file"
                    ref="avatar"
                    accept="image/*"
                    @change="avatarFileChanged"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form lazy-validation ref="profileForm">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="name"
                        :label="labels.name + '*'"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="last_name"
                        :label="labels.last_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="email"
                        :label="labels.email + '*'"
                        :rules="[rules.required]"
                        disabled
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        :label="labels.language + '*'"
                        :rules="[rules.required]"
                        v-model="lang"
                        :items="langs"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </app-form-page>
    <change-password-form
      v-if="showChangePasswordDialog"
      :show="showChangePasswordDialog"
      v-on:hide-dialog="showChangePasswordDialog = false"
    />
  </div>
</template>

<script>
const ChangePasswordForm = () =>
  import(/* webpackChunkName: "profile" */ "@/views/auth/ChangePassword");
export default {
  name: "Profile",
  components: {
    "change-password-form": ChangePasswordForm,
  },
  data: () => ({
    entity: "profile",
    langs: [{ value: "es", text: "Español" }],
    uploading: false,
    updating: false,
    updatingPassword: false,
    items: [
      {
        text: "Perfil",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    showChangePasswordDialog: false,
  }),
  methods: {
    changeAvatarClick() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.avatar.click();
    },
    avatarFileChanged() {
      this.selectedFile = this.$refs.avatar.files[0];
      var data = {
        image: this.selectedFile,
      };
      this.uploading = true;
      this.$store
        .dispatch("auth/uploadAvatar", data)
        .then(() => {
          this.$store.commit("snackbar/show", {
            text: this.labels.events.success.update_avatar,
            type: "success",
          });
        })
        .catch(() => {
          this.$store.commit("snackbar/show", {
            text: this.labels.events.error.update_avatar,
            type: "error",
          });
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    updateProfile() {
      if (this.$refs.profileForm.validate()) {
        this.updating = true;
        this.$store
          .dispatch("auth/updateProfile")
          .then(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.profile_updated,
              type: "success",
            });
          })
          .finally(() => {
            this.updating = false;
          });
      } else {
        this.$store.commit("snackbar/show", {
          text: this.labels.form_validation,
          type: "red lighten-1",
        });
      }
    },
    updatePassword() {
      if (this.$refs.profileForm.validate()) {
        this.updatingPassword = true;
        this.$store
          .dispatch("auth/changePassword")
          .then(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.profile_updated,
              type: "success",
            });
          })
          .catch(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.form_validation,
              type: "error",
            });
          })
          .finally(() => {
            this.updatingPassword = false;
          });
      } else {
        this.$store.commit("snackbar/show", {
          text: this.labels.form_validation,
          type: "red lighten-1",
        });
      }
    },
  },
  computed: {
    progress: {
      get() {
        return this.uploading || this.updating || this.updatingPassword;
      },
    },
    labels: {
      get() {
        return this.$store.getters["labels"](this.entity);
      },
    },
    rules: {
      get() {
        return this.$store.getters["rules"];
      },
    },
    userInitials: {
      get() {
        return this.$store.getters["auth/userInitials"];
      },
    },
    userFullName: {
      get() {
        return this.$store.getters["auth/userFullName"];
      },
    },
    email: {
      get() {
        return this.$store.state.auth.user.email;
      },
    },
    avatar: {
      get() {
        return this.$store.state.auth.user.avatar;
      },
    },
    name: {
      get() {
        return this.$store.state.auth.user.name;
      },
      set(value) {
        this.$store.commit("auth/SET_CURRENT_USER_ELEMENT", {
          name: "name",
          value: value,
        });
      },
    },
    last_name: {
      get() {
        return this.$store.state.auth.user.last_name;
      },
      set(value) {
        this.$store.commit("auth/SET_CURRENT_USER_ELEMENT", {
          name: "last_name",
          value: value,
        });
      },
    },
    lang: {
      get() {
        return this.$store.state.auth.user.lang;
      },
      set(value) {
        this.$store.commit("auth/SET_CURRENT_USER_ELEMENT", {
          name: "lang",
          value: value,
        });
      },
    },
  },
};
</script>
