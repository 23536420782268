<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card class="mx-auto pt-2" max-width="500px" elevation="8">
        <v-card-title class="mt-2 justify-center py-0">
          <v-label>
            <h2>{{ labels.title }}</h2>
          </v-label>
        </v-card-title>
        <v-card-text v-if="!userCreated" class="pb-0">
          <v-form ref="signUpForm">
            <v-container class="mb-0 pb-0">
              <v-row no-gutters>
                <v-col>
                  <v-alert
                    v-if="error && typeof error === 'string'"
                    type="error"
                    >{{ error }}</v-alert
                  >
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-alert v-if="error.errors" type="error">{{
                    error.errors.email[0]
                  }}</v-alert>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <v-text-field
                    :label="labels.name + '*'"
                    name="name"
                    type="text"
                    v-model="name"
                    v-on:change="resetError"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="labels.last_name"
                    type="text"
                    v-model="last_name"
                    v-on:change="resetError"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="labels.email + '*'"
                    name="email"
                    prepend-inner-icon="mdi-email-outline"
                    type="text"
                    v-model="email"
                    v-on:change="resetError"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    id="password"
                    :label="labels.password + '*'"
                    name="password"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    v-model="password"
                    v-on:change="resetError"
                    @keydown.enter="login"
                    :rules="[rules.required, rules.password_size]"
                    :hint="labels.password_hint"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    id="confirm_password"
                    :label="labels.confirm_password + '*'"
                    name="confirm_password"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="
                      showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :type="showPasswordConfirm ? 'text' : 'password'"
                    @click:append="showPasswordConfirm = !showPasswordConfirm"
                    v-model="confirmPassword"
                    v-on:change="resetError"
                    @keydown.enter="login"
                    :rules="[rules.required, rules.password_size]"
                    :hint="labels.password_hint"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-text v-if="userCreated">
          <v-container class="mb-0 pb-0">
            <v-row no-gutters>
              <v-col>
                <v-alert type="success">{{ labels.submitted }}</v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-0 pt-0 justify-center">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <v-btn
                  width="150px"
                  large
                  rounded
                  to="/login"
                  v-if="userCreated"
                  >{{ labels.return }}</v-btn
                >
                <v-btn
                  color="primary"
                  width="200px"
                  large
                  rounded
                  @click="sendUser"
                  :loading="loading"
                  v-if="!userCreated"
                  >{{ labels.submit }}</v-btn
                >
              </v-col>
            </v-row>
            <v-divider class="mt-1" v-if="!userCreated"></v-divider>
            <v-row no-gutters class="mt-2" align="center" v-if="!userCreated">
              <v-col cols="12" class="text-center">
                <v-label>{{ labels.have_account }} </v-label>
                <v-btn
                  text
                  color="primary"
                  to="login"
                  style="text-transform: none !important"
                  >{{ labels.login }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "SignUp",
  data: () => ({
    name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    loading: false,
    userCreated: false,
    showPassword: false,
    showPasswordConfirm: false,
  }),
  created() {},
  beforeMount() {
    this.resetError();
  },
  beforeDestroy() {
    this.resetError();
  },
  methods: {
    resetError() {
      this.$store.commit("auth/SET_ERROR", {});
    },
    sendUser() {
      this.resetError();
      if (this.$refs.signUpForm.validate()) {
        if (this.password != this.confirmPassword) {
          this.$store.commit(
            "auth/SET_ERROR",
            "No coincide la contraseña con la confirmación."
          );
        } else {
          this.loading = true;
          this.$store
            .dispatch("auth/signUp", {
              name: this.name,
              last_name: this.last_name,
              email: this.email,
              password: this.password,
              password_confirmation: this.confirmPassword,
              token: this.token,
            })
            .then(() => {
              this.loading = false;
              if (Object.keys(this.error).length === 0) {
                this.userCreated = true;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
  },
  computed: {
    error: {
      get() {
        return this.$store.state.auth.error;
      },
    },
    labels: {
      get() {
        return this.$store.getters["labels"]("sign_up");
      },
    },
    rules: {
      get() {
        return this.$store.getters["rules"];
      },
    },
  },
};
</script>